import React, { useState, useEffect, useContext, createContext } from "react";
import { Auth } from "aws-amplify";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { canUsePolicy } from '@/lib/utils';
import { useRouter } from "next/router";
import {getAuthSession, signOut as cognitoSignOut} from "../lib/cognito";

const VIEWER = gql`
  query viewer{
    viewer {
      id
      fullName
      email
      photo
      isStaff
      currentPolicyMap
      currentOrganization {
        id
        name
        photo
        status
        enabledAsReferrer
        featureFlags {
          key
        }
        clearCareLinkedAgency {
          agencyId
        }
        getOrganizationSettingValue(key:"looker-embedded-reports-demo", defaultValue: "") {
        	key
        	value
      	}
        timezone
        type {
          code
        }
      }
      widgetUserToken
      customFormsToken
    }
  }
`;

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

export const hasOrganizationType = (orgTypeCode, currentAccount) => {
  return !!currentAccount?.currentOrganization?.type?.some(
    (x) => x?.code?.toLowerCase() === orgTypeCode.toLowerCase()
  );
};

function useProvideAuth() {
  const router = useRouter();
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [userError, setUserError] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const viewerQuery = useQuery(VIEWER, {
    skip: !jwt,
    errorPolicy: "all",
  });

  const initUserFromSession = async () => {
    const { asPath } = router;
    try {
      const result = await getAuthSession(); //await Auth.currentSession();
      console.log("getAuthSession result =>", result);
      setUser(result);
      setJwt(result?.getIdToken().getJwtToken());
    } catch (error) {
      setUserError(error);
      // if (asPath.includes("loggedOut")) return;
      // window.location = "/?loggedOut=true";
    }
  };

  useEffect(() => {
    console.log("useAuth: Default useEffect")
    initUserFromSession();
  }, []);

  useEffect(() => {
    setCurrentAccount(viewerQuery?.data?.viewer);
    setCurrentOrganization(viewerQuery?.data?.viewer?.currentOrganization);
    const isDataReady = viewerQuery?.error || viewerQuery?.data;
    setUserLoading(!isDataReady || viewerQuery?.loading);
    setUserError(viewerQuery?.error);
  }, [viewerQuery]);

  const signIn = async (email, password) => {
    let user;
    try {
      user = await Auth.signIn(email.toLowerCase().trim(), password.trim());
    }
    catch(e) {
      if (e.code === 'UserNotFoundException') {
        console.debug('user not migrated? trying case-sensitive username');
        user = await Auth.signIn(email.trim(), password.trim());
      }
      else {
        throw(e);
      }
    }
    setUser(user);
    initUserFromSession();
    return user;
  };

  const signInWithUser = async (user) => {
    setUser(user);
    initUserFromSession();
    return user;
  };

  const signOut = async () => {
    const result = await cognitoSignOut(); //await Auth.signOut(); 
    // state.apolloClient.cache.reset();
    window.location = "/?loggedOut=true";
    setUser(null);
  };

  const canViewPolicy = (policy) =>
    canUsePolicy(currentAccount, policy, ["VIEW", "MANAGE", "CREATE", "UPDATE", "DELETE"]);

  const canManagePolicy = (policy) =>
    canUsePolicy(currentAccount, policy, ["MANAGE"]);

  const canCreatePolicy = (policy) =>
    canUsePolicy(currentAccount, policy, ["CREATE", "MANAGE"]);

  const canUpdatePolicy = (policy) =>
    canUsePolicy(currentAccount, policy, ["UPDATE", "MANAGE"]);

  const canDeletePolicy = (policy) =>
    canUsePolicy(currentAccount, policy, ["DELETE", "MANAGE"]);  
  
    const _hasOrganizationType = (orgTypeCode) =>
    hasOrganizationType(orgTypeCode, currentAccount);

  // Return the user object and auth methods
  return {
    user,
    userLoading,
    userError,
    currentAccount,
    currentOrganization,
    refresh: viewerQuery?.refetch,
    signIn,
    signInWithUser,
    signOut,
    canCreatePolicy,
    canDeletePolicy,
    canUpdatePolicy,
    canViewPolicy,
    canManagePolicy,
    hasOrganizationType: _hasOrganizationType,
  };
}
