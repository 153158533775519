import { ProvideAuth } from "@/hooks/useAuth";
import { ApolloProvider } from "react-apollo";
import App from "next/app";
import Head from "next/head";
import React from "react";
import withApolloClient from "@/lib/with-apollo-client";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ChakraWrapper } from '../wrappers/chakra-wrapper';

// import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import NProgress from "nprogress";
import Router from "next/router";

//Sentry
import * as Sentry from "@sentry/browser";

import AWS_AppSync from "../AppSync.json";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isMoment from "dayjs/plugin/isMoment";
import badMutable from "dayjs/plugin/badMutable";
import localeData from "dayjs/plugin/localeData";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekYear from "dayjs/plugin/weekYear";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
dayjs.extend(isMoment);
dayjs.extend(localeData);
dayjs.extend(badMutable);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.locale("en");

Router.onRouteChangeStart = () => NProgress.start();
Router.onRouteChangeComplete = () => NProgress.done();
Router.onRouteChangeError = () => NProgress.done();

if (typeof window !== 'undefined') {
  function isFilteredRequestError(event) {
    // In case there's a chain, we take the last entries
    let mainAndMaybeCauseErrors = [];
    if (
      event &&
      event.exception &&
      event.exception.values &&
      event.exception.values.length > 0
    ) {
      mainAndMaybeCauseErrors = event.exception.values.slice(-2);
    }

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    for (const error of mainAndMaybeCauseErrors) {
      const failedToFetch = error.value.includes('Network error: Failed to fetch');

      if (failedToFetch) {
        return true;
      }

      const eIsNotFunction =(isSafari && 
        error.value === "e is not a function. (In 'e()', 'e' is undefined)");

      if (eIsNotFunction) {
        return true;
      }
    }

    return false;
  }

  Sentry.init({
    dsn: "https://86d61276b5984f80ade54f6e18ffe1f0@sentry.io/1435912",
    environment: AWS_AppSync.environment || "dev",
    ignoreErrors: ["Received status code 401", "Not Authorized to access"],
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 0.2 to capture 20%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.2,
    beforeSend(event, hint) {
      if (isFilteredRequestError(event)) {
        // Return null to stop the error from being sent to Sentry
        return null;
      }
      return event;
    },
  });
}

// TODO: remove ApolloHooksProvider when the Apollo community formalizes on Hooks
// https://github.com/apollographql/react-apollo/issues/2539
class MyApp extends App {
  render() {
    const { Component, pageProps, client, query } = this.props;
    const newProps = { ...pageProps, query, client, apolloClient: client };
    const isChakraPage = !!Component.defaultProps?.chakra;

    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <ProvideAuth>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta name="theme-color" content="#ffffff"></meta>
              <meta name="robots" content="noindex"></meta>
              <title>Care Coordination Portal</title>
              {!isChakraPage && [
                <link key="antd-styles" rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/3.20.7/antd.min.css" />,
                <link key="site-styles" rel="stylesheet" href="/static/site.css" />,
                <link key="tailwind-styles" rel="stylesheet" href="/static/styles/tailwind.min.css" />,
                <link key="styles-styles" rel="stylesheet" href="/static/styles/styles.css" />,
                <link key="codemirror-styles" rel="stylesheet" href="/static/styles/codemirror.css" />,
                <link key="material-styles" rel="stylesheet" href="/static/styles/material.css" />
              ]}
            </Head>
            {isChakraPage ? (
              <ChakraWrapper>
                <Component {...newProps} />
              </ChakraWrapper>
            ) : <Component {...newProps} />}
          </ProvideAuth>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default withApolloClient(MyApp);
