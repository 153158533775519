import Amplify from '@/lib/aws';
import jwt_decode from 'jwt-decode';
import AWS_AppSync from '../../app/AppSync.json';

const getIdentityId = () => {
  // const creds = await Amplify.Auth.currentCredentials();
  // // console.log('CREDS', creds);
  // return creds?.data?.IdentityId || AWS_AppSync.userIdentityPoolId;
  return AWS_AppSync.userIdentityPoolId;
};

const setDevProvidedAuth = async dev_provided_token => {
  if (dev_provided_token) {
    return; //deprecating oidc flow
    window.localStorage.setItem('cognito-dev-provided-identity', dev_provided_token);
  }
};

const getAuthSession = async () => {
  try {
    return await Amplify.Auth.currentSession();
  } catch (e) {
    console.log('Exception =>', e);
    console.log(
      'cognito-dev-provided-identity : ' +
        window.localStorage.getItem('cognito-dev-provided-identity'),
    );
    if (window.localStorage.getItem('cognito-dev-provided-identity')) {
      const result = {
        getIdToken: () => {
          return {
            getJwtToken: () => {
              const token = window.localStorage.getItem('cognito-dev-provided-identity');
              const { exp } = jwt_decode(token);
              if (Date.now() >= exp * 1000) {
                window.localStorage.removeItem('cognito-dev-provided-identity');
                throw 'token expired';
              }
              return token;
            },
          };
        },
      };
      return result;
    } else if (window.localStorage.getItem('oauth-id-token')) {
      console.log('OAuth token path');
      const result = {
        getIdToken: () => {
          return {
            getJwtToken: () => {
              console.log(
                'Looking for OAuth token - ',
                window.localStorage.getItem('oauth-id-token'),
              );
              const token = window.localStorage.getItem('oauth-id-token');
              const { exp } = jwt_decode(token);
              if (Date.now() >= exp * 1000) {
                window.localStorage.removeItem('oauth-id-token');
                throw 'token expired';
              }
              return token;
            },
          };
        },
      };
      return result;
    }
    throw e;
  }
};

const signOut = async () => {
  window.localStorage.removeItem('cognito-dev-provided-identity');
  window.localStorage.removeItem('oauth-id-token');
  window.localStorage.removeItem('sso-login');
  sessionStorage.clear();
  return await Amplify.Auth.signOut();
};

const setTimelineToken = value => {
  window.localStorage.removeItem('timelineToken');
  window.localStorage.setItem('timelineToken', value);
};
const getTimelineToken = () => {
  return window.localStorage.getItem('timelineToken');
};

const signOutOnlyIfDevProvidedIdentityFlow = async () => {
  if (window.localStorage.getItem('cognito-dev-provided-identity')) {
    const token = window.localStorage.getItem('cognito-dev-provided-identity');
    if (token) {
      const { exp } = jwt_decode(token);
      if (Date.now() >= exp * 1000) {
        window.localStorage.removeItem('cognito-dev-provided-identity');
        await signOut();
        window.location = '/?loggedOut=true';
      }
    }
  }
};

const refreshDevProvidedIdentityToken = async dev_provided_token => {
  // update local storage with new token
  window.localStorage.removeItem('cognito-dev-provided-identity');
  window.localStorage.setItem('cognito-dev-provided-identity', dev_provided_token);
};

const isOIDCFlow = async () => {
  // if (window.localStorage.getItem("cognito-dev-provided-identity")) {
  //     return true;
  // }
  return false;
};

export {
  getIdentityId,
  getAuthSession,
  signOut,
  setDevProvidedAuth,
  signOutOnlyIfDevProvidedIdentityFlow,
  isOIDCFlow,
  refreshDevProvidedIdentityToken,
  setTimelineToken,
  getTimelineToken,
};
