import { Amplify } from 'aws-amplify';
import fetch from 'isomorphic-unfetch';
import AWS_AppSync from '../AppSync.json';

// Polyfill fetch() on the server
if (!process.browser) {
  global.fetch = fetch;
}

Amplify.configure({
  aws_appsync_graphqlEndpoint: AWS_AppSync.graphqlEndpoint,
  aws_appsync_region: AWS_AppSync.region,
  aws_appsync_authenticationType: AWS_AppSync.authenticationType,
  Auth: {
    Cognito: {
      userPoolClientId: AWS_AppSync.userPoolWebClientId,
      userPoolId: AWS_AppSync.userPoolId,
    },

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: AWS_AppSync.userIdentityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: AWS_AppSync.region,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: AWS_AppSync.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: AWS_AppSync.userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: AWS_AppSync.authenticationType,

    // OPTIONAL - Configuration for cookie storage
    // cookieStorage: {
    //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: 'localhost',
    //     // OPTIONAL - Cookie path
    //     path: '/',
    //     // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    //     // OPTIONAL - Cookie secure flag
    //     secure: false
    // }
  },
});

export default Amplify;
