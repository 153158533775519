import React from "react";
import initApollo from "./init-apollo";
import urlUtils from "./url-utils";

const WithApolloClient = (App) => {
  return class Apollo extends React.Component {
    static displayName = "withApollo(App)";
    
    constructor(props) {
      super(props);
      const query = urlUtils.parseQueryString();
      this.apolloClient = initApollo({}, query["token"]);
    }

    render() {
      const query = urlUtils.parseQueryString();
      return (
        <App
          {...this.props}
          query={query}
          client={this.apolloClient}
          apolloClient={this.apolloClient}
        />
      );
    }
  };
};

export default WithApolloClient;
